<div
  id="actionBar"
  class="d-flex align-items-end bg-white"
>
  <div class="container-fluid">
    <div class="d-flex w-100 actionBar-content align-items-end">
      <div class="flex-fill">
        <div class="sub-title pb-0">Historical Rides</div>
      </div>
    </div>
  </div>
</div>

<br />
<div class="content-wrapper container-fluid">
  <div class="content-body">
    <div>
      <app-statistics [statisticsList]="statistics"></app-statistics>

      <div class="card">
        <div>
          <app-collection-filters
            (search)="getRiderhisoricaldata()"
            [filters]="FiltersConfig"
          >
          </app-collection-filters>
          <kendo-grid-spacer></kendo-grid-spacer>
        </div>

        <div class="card-body">
          <kendo-grid
            #mainGrid
            [data]="GridData"
            [pageSize]="state.take"
            [skip]="state.skip"
            [sortable]="true"
            [sort]="state.sort"
            [filter]="state.filter"
            [pageable]="false"
            (sortChange)="sortChange($event)"
          >
            <ng-template
              kendoGridToolbarTemplate
              NgClass="p-0"
            >
              <div class="col-lg-12 toolbar-control">
                <div class="row">
                  <div class="col-md-6 col-12 pb-0">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <label
                        class="d-flex align-items-center"
                        disaled
                      >
                        Show
                        <select
                          (change)="changeGridIndex()"
                          [(ngModel)]="gridIndex"
                          class="form-control mx-25 form-control-sm ng-pristine ng-valid ng-touched"
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        entries
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <kendo-grid-spacer></kendo-grid-spacer>
            </ng-template>
            <kendo-grid-column
              [width]="50"
              [sortable]="false"
              title="#"
            >
              <ng-template
                kendoGridCellTemplate
                let-rowIndex="rowIndex+1"
              >
                {{ rowIndex }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              field="DateOfService"
              title="Date"
              [sortable]="false"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
              >
                {{ dataItem.DateOfService | date: "MM/dd/YYYY" }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              field="ride"
              title="Ride"
              [sortable]="true"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                let-rowIndex="rowIndex"
              >
                <a
                  class="btn btn-link pl-0 pr-0 d-block text-left p-0"
                  (click)="
                    modalOpenLG(
                      RideSummary,
                      dataItem.RideId,
                      dataItem.RideUuid,
                      'RideSummary',
                      ''
                    )
                  "
                >
                  {{ dataItem.RideId }}
                </a>
                <div [ngSwitch]="dataItem.RideStatusId">
                  <span
                    class="badge badge-secondary"
                    *ngSwitchCase="CanceledInAdvance"
                  >
                    Early Cancel
                  </span>
                  <span
                    class="badge badge-secondary"
                    *ngSwitchCase="CanceledDueToHoliday"
                  >
                    Holiday
                  </span>
                  <span
                    class="badge badge-light"
                    *ngSwitchCase="CanceledDueToPandemic"
                  >
                    Pandemic Holiday
                  </span>
                  <span
                    class="badge badge-success"
                    *ngSwitchCase="Completed"
                  >
                    Completed
                  </span>
                  <span
                    class="badge badge-success"
                    *ngSwitchCase="NoShow"
                  >
                    No Show
                  </span>
                  <span
                    class="badge badge-success"
                    *ngSwitchCase="CanceledLate"
                  >
                    Late Cancel
                  </span>
                </div>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              field="Type"
              title="Type & Time"
              [sortable]="false"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
              >
                {{ dataItem.RideType }}
                <span class="hint"> ({{ dataItem.VehicleTypeName }}) </span>
                <br />
                {{ dataItem.ScheduledFirstArrivalTime | date: "shortTime" }} -
                {{ dataItem.ScheduledLastArrivalTime | date: "shortTime" }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              field="RideBlueprintId"
              title=" "
              [width]="300"
              [sortable]="false"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                let-rowIndex="rowIndex"
              >
                <span class="hint"> Blueprint #</span>
                <a
                  class="btn btn-link pl-0 pr-0 d-inline"
                  (click)="
                    modalOpenLG(
                      RideBlueprintSheet,
                      0,
                      dataItem.RideBlueprintUuid,
                      'RideBlueprintSheet',
                      ''
                    )
                  "
                >
                  {{ dataItem.RideBlueprintId }}
                </a>
                <br />
                <div *ngFor="let item of dataItem.PayingClients">
                  {{ item.ClientName }}
                </div>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              field="Jobs"
              title="Contractor(s)"
              [width]="250"
              [sortable]="false"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                let-rowIndex="rowIndex"
              >
                <div *ngFor="let item of dataItem.Jobs">
                  <a
                    class="btn btn-link pl-0 pr-0 d-block text-left p-0"
                    (click)="
                      modalOpenLG(
                        DriverDetails,
                        item.PartnerId,
                        dataItem.RideUuid,
                        'dreiverDetails',
                        item.PartnerFirstOrNickName
                      )
                    "
                  >
                    {{ item.PartnerFirstOrNickName }}
                  </a>
                  <span class="hint"> #{{ item.PartnerId }}</span>
                </div>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              [sortable]="false"
              title="Action"
              [width]="90"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                let-rowIndex="rowIndex"
              >
                <div class="d-flex align-items-center">
                  <div
                    ngbDropdown
                    [placement]="'left-top'"
                    container="body"
                    class="m-auto"
                  >
                    <a
                      ngbDropdownToggle
                      href="javascript:void(0);"
                      class="hide-arrow"
                      id="dropdownBrowserState"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i
                        data-feather="more-vertical"
                        class="text-primary cursor-pointer mr-50"
                      ></i>
                    </a>
                    <div
                      ngbDropdownMenu
                      class="dropdown-menu-right"
                      aria-labelledby="dropdownBrowserState"
                    >
                      <a
                        href="javascript:void(0)"
                        (click)="
                          modalOpenRideMonitorModel(RideActivity, dataItem)
                        "
                        ngbDropdownItem
                        class="d-flex align-items-center"
                      >
                        View Ride Activity
                      </a>
                    </div>
                  </div>
                </div>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>

          <div class="col-lg-12 text-center Load-More-section">
            <button
              [disabled]="
                disableLoadMore && !(GridData.total >= GridData.data.length)
              "
              class="btn btn-light btn-Load-More btn-sm"
              (click)="viewMore()"
            >
              load more
            </button>
            <div class="page-count ng-star-inserted">
              {{
                GridData.total >= GridData.data.length
                  ? GridData.data.length
                  : GridData.total
              }}
              of {{ GridData.total }} record
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-size-lg d-inline-block">
    <!-- Modal -->
    <ng-template
      #RideSummary
      let-modal
    >
      <div class="modal-header">
        <h5
          class="modal-title"
          id="myModalLabel160"
        >
          Ride Summary #{{ rideInfo.RideId }}
          <div [ngSwitch]="rideInfo.RideStatusId">
            <span
              class="badge badge-secondary"
              *ngSwitchCase="CanceledInAdvance"
              >Early Cancel</span
            >
            <span
              class="badge badge-secondary"
              *ngSwitchCase="CanceledDueToHoliday"
              >Holiday</span
            >
            <span
              class="badge badge-secondary"
              *ngSwitchCase="CanceledDueToPandemic"
              >Pandemic Holiday</span
            >
            <span
              class="badge badge-success"
              *ngSwitchCase="Completed"
              >completed</span
            >
            <span
              class="badge badge-success"
              *ngSwitchCase="NoShow"
              >No Show</span
            >
            <span
              class="badge badge-success"
              *ngSwitchCase="CanceledLate"
              >Late Cancel</span
            >
          </div>
        </h5>
        <button
          type="button"
          class="close"
          (click)="modal.dismiss('Cross click')"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div
        class="modal-body"
        tabindex="0"
        ngbAutofocus
      >
        <div class="card-body invoice-padding py-0">
          <div class="my-2">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <div>
                <div class="logo-wrapper">
                  <h5 class="text-primary invoice-logo">Basic Details</h5>
                </div>
              </div>
            </div>
            <hr class="m-0 mb-2" />
          </div>
          <!-- Invoice Note starts -->
          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
              <div class="d-flex bd-highlight info-container">
                <div class="w-30 info-title">Type</div>
                <div class="w-70 info-value">{{ rideInfo.RideType }}</div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
              <div class="d-flex bd-highlight info-container">
                <div class="w-30 info-title">Service</div>
                <div class="w-70 info-value">
                  {{ rideInfo.RideServiceType }}
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
              <div class="d-flex bd-highlight info-container">
                <div class="w-30 info-title">Period</div>
                <div class="w-70 info-value">{{ rideInfo.RidePeriod }}</div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
              <div class="d-flex bd-highlight info-container">
                <div class="w-30 info-title">Student(s)</div>
                <div class="w-70 info-value">{{ (rideInfo.NumberOfCargo) - (rideInfo.NumberofAttendants) }}</div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
              <div class="d-flex bd-highlight info-container">
                <div class="w-30 info-title">Duration</div>
                <div class="w-70 info-value">
                  {{ rideInfo.EstimatedDurationInMinutes }} mins ({{
                    rideInfo.EstimatedDistanceInMiles
                  }}
                  mi)
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
              <div class="d-flex bd-highlight info-container">
                <div class="w-30 info-title">Monitor(s)</div>
                <div class="w-70 info-value">
                  {{ rideInfo.NumberofAttendants }}
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
              <div class="d-flex bd-highlight info-container">
                <div class="w-30 info-title">Client(s)</div>
                <div *ngFor="let item of rideInfo.Clients">
                  <div class="w-70 info-value">{{ item.Name }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- Invoice Note ends -->
        </div>

        <div class="invoice-preview-card mb-2">
          <!-- Header starts -->
          <div class="card-body invoice-padding pb-0">
            <div class="my-2">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <div>
                  <div class="logo-wrapper">
                    <h5 class="text-primary invoice-logo">Ride Overview</h5>
                  </div>
                </div>
              </div>
              <hr class="mt-0 mb-2" />
            </div>
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <div class="w-100">
                <ul class="timeline">
                  <div *ngFor="let item of waypoints; index as i">
                    <li class="timeline-item">
                      <span class="timeline-time timeline-stop-time">
                        {{ item.ScheduledArrivalTime | date: "shortTime" }}
                      </span>
                      <span
                        class="badge badge-secondary bg-info Stop-span pt-1 pb-1"
                      >
                        Stop {{ i + 1 }}
                      </span>
                      <span
                        class="timeline-point timeline-point-info timeline-point-indicator"
                      ></span>
                      <div class="timeline-event">
                        <div class="row">
                          <div class="col-lg-12 pb-0">
                            <p
                              class="Time-line-title text-primary bold mb-0 d-inline"
                            >
                              {{ item.Name }}
                            </p>
                          </div>
                          <div class="col-lg-12 pb-0">
                            <p class="pl-1 Time-line-title Text-secondary">
                              {{ item.Location.FullAddress }}
                            </p>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-lg-12 pb-0">
                            <div class="row">
                              <div class="col-lg-6 pb-0">
                                <div class="row">
                                  <div class="col-lg-12 pb-0">
                                    <p
                                      class="Time-line-title text-primary bold mb-0"
                                    >
                                      {{ item.ActionType }}
                                    </p>
                                  </div>
                                  <div
                                    class="col-lg-12 pb-0"
                                    *ngFor="let i of item.Cargo"
                                  >
                                    <p
                                      class="pl-1 Time-line-title Text-secondary"
                                    >
                                      {{ i.FirstName }} {{ i.LastName }}
                                      <span class="hint">
                                        ({{ i.CargoType }})
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                    </li>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body invoice-padding py-0">
          <div class="row">
            <div class="col-lg-6">
              <div class="my-1">
                <div
                  class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
                >
                  <div>
                    <div class="logo-wrapper">
                      <h5 class="text-primary invoice-logo">
                        Equipment Needed
                      </h5>
                    </div>
                  </div>
                </div>
                <hr class="m-0" />
              </div>
              <!-- Invoice Note starts -->
              <div class="row">
                <div class="col-12 pb-0">
                  <div>
                    <p
                      class="mb-75 text-secondary w-30 d-inline-block"
                      *ngIf="rideInfo.RideEquipment.length == 0"
                    >
                      None required.
                    </p>
                  </div>
                  <div *ngIf="rideInfo.RideEquipment.length > 0">
                    <p
                      class="mb-75 text-secondary w-30 d-inline-block"
                      *ngFor="let item of rideInfo.RideEquipment"
                    >
                      {{ item.NumberOfEquipment }} x {{ item.Name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Invoice Note ends -->
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-info"
            (click)="modal.close('Accept click')"
            rippleEffect
          >
            Close
          </button>
        </div>
      </div>
    </ng-template>
    <!-- / Modal -->
  </div>

  <div class="modal-size-lg d-inline-block">
    <!-- Modal -->
    <ng-template
      #RideBlueprintSheet
      let-modal
    >
      <div class="modal-header">
        <h5
          class="modal-title"
          id="myModalLabel160"
        >
          Ride Blueprint Sheet #{{ bluePrint.Id }}
        </h5>

        <button
          type="button"
          class="close"
          (click)="modal.dismiss('Cross click')"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div
        class="modal-body"
        tabindex="0"
        ngbAutofocus
      >
        <div class="card-body invoice-padding py-0">
          <div class="my-2">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <div>
                <div class="logo-wrapper">
                  <h5 class="text-primary invoice-logo">Basic Details</h5>
                </div>
              </div>
            </div>
            <hr class="m-0 mb-2" />
          </div>
          <!-- Invoice Note starts -->
          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
              <div class="d-flex bd-highlight info-container">
                <div class="w-30 info-title">Type</div>
                <div class="w-70 info-value">{{ bluePrint.Type }}</div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
              <div class="d-flex bd-highlight info-container">
                <div class="w-30 info-title">Service</div>
                <div class="w-70 info-value">
                  {{ bluePrint.VehicleTypeName }}
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
              <div class="d-flex bd-highlight info-container">
                <div class="w-30 info-title">Period</div>
                <div class="w-70 info-value">{{ bluePrint.Period }}</div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
              <div class="d-flex bd-highlight info-container">
                <div class="w-30 info-title">Student(s)</div>
                <div class="w-70 info-value">{{ bluePrint.NumberOfCargo }}</div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
              <div class="d-flex bd-highlight info-container">
                <div class="w-30 info-title">Duration</div>
                <div class="w-70 info-value">
                  {{ bluePrint.DurationInMinutes }} mins ({{
                    bluePrint.DistanceInMiles
                  }}
                  mi)
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
              <div class="d-flex bd-highlight info-container">
                <div class="w-30 info-title">Monitor(s)</div>
                <div class="w-70 info-value">
                  {{ bluePrint.NumberOfAttendants }}
                </div>
              </div>
            </div>
          </div>
          <!-- Invoice Note ends -->
        </div>

        <div class="invoice-preview-card mb-2">
          <!-- Header starts -->
          <div class="card-body invoice-padding pb-0">
            <div class="my-2">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <div>
                  <div class="logo-wrapper">
                    <h5 class="text-primary invoice-logo">Ride Overview</h5>
                  </div>
                </div>
              </div>
              <hr class="mt-0 mb-2" />
            </div>

            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <div class="w-100">
                <ul class="timeline">
                  <div *ngFor="let item of bluePrint.Waypoints; index as i">
                    <li class="timeline-item">
                      <span class="timeline-time timeline-stop-time">
                        {{ item.ScheduledArrivalTime | date: "shortTime" }}
                      </span>
                      <span
                        class="badge badge-secondary bg-info Stop-span pt-1 pb-1"
                      >
                        Stop {{ i + 1 }}
                      </span>
                      <span
                        class="timeline-point timeline-point-info timeline-point-indicator"
                      ></span>
                      <div class="timeline-event">
                        <div class="row">
                          <div class="col-lg-12 pb-0">
                            <p
                              class="Time-line-title text-primary bold mb-0 d-inline"
                            >
                              {{ item.Name }}
                            </p>
                            <span class="hint d-inline float-right pr-5 mb-0"
                              >Pick-up Instructions</span
                            >
                          </div>
                          <div class="col-lg-12 pb-0">
                            <p class="pl-1 Time-line-title Text-secondary">
                              {{ item.FullAddress }}
                            </p>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-lg-12 pb-0">
                            <div class="row">
                              <div class="col-lg-6 pb-0">
                                <div class="row">
                                  <div class="col-lg-12 pb-0">
                                    <p
                                      class="Time-line-title text-primary bold mb-0"
                                    >
                                      {{ item.ActionType }}
                                    </p>
                                  </div>
                                  <div
                                    class="col-lg-12 pb-0"
                                    *ngFor="let element of item.Cargo"
                                  >
                                    <p
                                      class="pl-1 Time-line-title Text-secondary"
                                    >
                                      {{ element.Name
                                      }}<span class="hint">
                                        ({{
                                          element.CargoType == "Assistant"
                                            ? "Client Monitor"
                                            : element.CargoType == "Aide" ||
                                                element.CargoType ==
                                                  "Attendant" ||
                                                element.CargoType ==
                                                  "Attentdant"
                                              ? "Monitor"
                                              : element.CargoType
                                        }})
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12 mt-1">
                          <p
                            *ngIf="bluePrint.ScheduleTimeAdjustments != null"
                            class="Time-line-title text-primary ride-sub-title bold mb-0 d-inline"
                          >
                            Weekly Schedule Adjustments
                          </p>
                          <ul class="disc-list ride-Schedule-list">
                            <li
                              *ngFor="
                                let ScheduleTimeAdjustment of bluePrint.ScheduleTimeAdjustments
                              "
                            >
                              {{ ScheduleTimeAdjustment.TypeName }}
                              {{
                                ScheduleTimeAdjustment.RecurrenceDay == "2"
                                  ? "every " + "Monday"
                                  : ScheduleTimeAdjustment.RecurrenceDay == "3"
                                    ? "every " + "Tuesday"
                                    : ScheduleTimeAdjustment.RecurrenceDay ==
                                        "4"
                                      ? "every " + "Wednesday"
                                      : ScheduleTimeAdjustment.RecurrenceDay ==
                                          "5"
                                        ? "every " + "Thursday"
                                        : ScheduleTimeAdjustment.RecurrenceDay ==
                                            "6"
                                          ? "every " + "Friday"
                                          : ScheduleTimeAdjustment.RecurrenceDay ==
                                              "7"
                                            ? "every " + "Saturday"
                                            : "every " + "Sunday"
                              }}
                              at
                              {{
                                moment(
                                  item.ScheduledArrivalTime,
                                  ScheduleTimeAdjustment.AdjustedDurationInMinutes
                                )
                              }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <br />
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body invoice-padding py-0">
          <div class="row">
            <div class="col-lg-6">
              <div class="my-1">
                <div
                  class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
                >
                  <div>
                    <div class="logo-wrapper">
                      <h5 class="text-primary invoice-logo">
                        Equipment Needed
                      </h5>
                    </div>
                  </div>
                </div>
                <hr class="m-0" />
              </div>
              <!-- Invoice Note starts -->
              <div class="row">
                <div class="col-12 pb-0">
                  <div>
                    <p
                      class="mb-75 text-secondary w-30 d-inline-block"
                      *ngIf="bluePrint.Equipment.length == 0"
                    >
                      None required.
                    </p>
                  </div>
                  <div *ngIf="bluePrint.Equipment.length > 0">
                    <p
                      class="mb-75 text-secondary w-30 d-inline-block"
                      *ngFor="let item of bluePrint.Equipment"
                    >
                      {{ item.NumberOfEquipment }} x {{ item.Name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="my-1">
                <div
                  class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
                >
                  <div>
                    <div class="logo-wrapper">
                      <h5 class="text-primary invoice-logo">Calendars</h5>
                    </div>
                  </div>
                </div>
                <hr class="m-0" />
              </div>
              <!-- Invoice Note starts -->
              <div class="row">
                <div class="col-12 pb-0">
                  <div>
                    <p
                      *ngIf="bluePrint.Destinations.length <= 0"
                      class="mb-75 text-secondary d-inline-block"
                    >
                      None.
                    </p>
                    <div
                      *ngIf="bluePrint.Destinations.length > 0"
                      class="mb-75 text-secondary d-inline-block"
                    >
                      <p *ngFor="let calendar of bluePrint.Destinations">
                        {{ calendar.PlaceScheduleName }} ({{
                          calendar.PlaceScheduleNickName
                        }}) - {{ calendar.Name }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Invoice Note ends -->
        </div>
        <div
          *ngIf="bluePrint.PlaceInstructions.length > 0"
          class="card-body invoice-padding py-0"
        >
          <div class="my-1">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <div>
                <div class="logo-wrapper">
                  <h5 class="text-primary invoice-logo">Place Instructions</h5>
                </div>
              </div>
            </div>
            <hr class="m-0" />
          </div>
          <div class="row">
            <div class="col-12 pb-0">
              <table class="table adr-table">
                <caption>
                  Instructions Table
                </caption>
                <thead class="thead-default">
                  <tr>
                    <th>#</th>
                    <th>Type</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let instructios of bluePrint.PlaceInstructions;
                      index as indexOfelement
                    "
                    role="row"
                    class="odd"
                  >
                    <td>
                      {{ indexOfelement + 1 }}
                    </td>

                    <td>
                      {{ instructios.Type }}
                      <br />
                    </td>

                    <td>
                      <div>
                        {{ instructios.Description }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card-body invoice-padding py-0">
          <div class="my-1">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <div>
                <div class="logo-wrapper">
                  <h5 class="text-primary invoice-logo">Passenger(s)</h5>
                </div>
              </div>
            </div>
            <hr class="m-0" />
          </div>
          <div class="row">
            <div class="col-12 pb-0">
              <table
                class="table adr-table"
                style="width: 100% !important"
              >
                <caption>
                  Equipment Table
                </caption>
                <thead class="thead-default">
                  <tr>
                    <th>#</th>
                    <th style="padding-left: 0">Name</th>
                    <th style="padding-left: 0">Contact(s)</th>
                    <th style="padding-left: 0">Equipment</th>
                    <th style="padding-left: 0">Instructions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let stu of bluePrint.Cargo; index as indexOfelement"
                    role="row"
                    class="odd"
                  >
                    <td>
                      {{ indexOfelement + 1 }}
                    </td>

                    <td style="padding-left: 0">
                      {{ stu.FullName }} <br />
                      <span class="text-muted">
                        ({{
                          stu.CargoType == "Assistant"
                            ? "Client Monitor"
                            : stu.CargoType == "Aide" ||
                                stu.CargoType == "Attendant" ||
                                stu.CargoType == "Attentdant"
                              ? "Monitor"
                              : stu.CargoType
                        }})
                      </span>
                      <br />
                    </td>

                    <td
                      *ngFor="let car of bluePrint.Cargo"
                      style="padding-left: 0"
                    >
                      <div>
                        {{ ContactPassengers(car.Contacts) }}
                      </div>
                    </td>

                    <td style="padding-left: 0">
                      <div
                        *ngIf="bluePrint.Cargo[0].Equipment.length == 0"
                        class="d-block"
                      >
                        <p>None required</p>
                      </div>

                      <div
                        *ngFor="let equipment of bluePrint.Cargo"
                        class="d-block"
                      >
                        {{ Equipments(equipment.Equipment) }}
                        <br />
                      </div>
                    </td>

                    <td style="padding-left: 0; padding-right: 36px !important">
                      <button
                        type="button"
                        rippleEffect
                        *ngIf="CargoInstructions(stu) != ''"
                        class="btn btn-link pl-0 pr-0 text-left"
                        [ngbPopover]="elseBlock"
                        placement="right"
                        triggers="mouseenter:mouseleave"
                      >
                        View Instructions
                      </button>
                      <button
                        *ngIf="CargoInstructions(stu) == ''"
                        type="button"
                        rippleEffect
                        class="btn btn-link pl-0 pr-0 text-left"
                        disabled
                        placement="right"
                        triggers="mouseenter:mouseleave"
                        style="color: gray !important"
                      >
                        View Instructions
                      </button>
                      <ng-template #elseBlock
                        ><span
                          style="display: block; width: 500px; height: 500px"
                          [outerHTML]="CargoInstructions(stu)"
                        ></span
                      ></ng-template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card-body invoice-padding py-0">
          <div class="my-1">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <div>
                <div class="logo-wrapper">
                  <h5 class="text-primary invoice-logo">Destination(s)</h5>
                </div>
              </div>
            </div>
            <hr class="m-0" />
          </div>
          <div class="row">
            <div class="col-12 pb-0">
              <table
                class="table adr-table"
                style="width: 100% !important"
              >
                <caption>
                  Contact Table
                </caption>
                <thead class="thead-default">
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Contact(s)</th>
                    <th style="width: 150px">Note(s)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let destination of bluePrint.Destinations;
                      index as indexOfelement
                    "
                    role="row"
                    class="odd"
                  >
                    <td>
                      {{ indexOfelement + 1 }}
                    </td>

                    <td>
                      {{ destination.Name }}
                    </td>

                    <td *ngFor="let contact of bluePrint.Destinations">
                      <div class="d-block">
                        {{ ContactDestinations(contact.Contacts) }}
                      </div>
                    </td>

                    <td>
                      <div *ngIf="destination.Note != ''">
                        <button
                          type="button"
                          rippleEffect
                          class="btn btn-link pl-0 pr-0 text-left"
                          ngbPopover="{{ destination.Note }}"
                          placement="right"
                          triggers="mouseenter:mouseleave"
                          [disabled]="destination.Note == ''"
                        >
                          View Note
                        </button>
                      </div>
                      <div *ngIf="destination.Note == ''">-</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-info"
            (click)="modal.close('Accept click')"
            rippleEffect
          >
            Close
          </button>
        </div>
      </div>
    </ng-template>
    <!-- / Modal -->
  </div>

  <div class="modal-size-lg d-inline-block">
    <!-- Modal -->
    <ng-template
      #DriverDetails
      let-modal
    >
      <div class="modal-header">
        <h5
          class="modal-title"
          id="myModalLabel160"
        >
          Driver Details for {{ driverProfile.Name }} #{{ driverProfile.Id }}
        </h5>
        <button
          type="button"
          class="close"
          (click)="modal.dismiss('Cross click')"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div
        class="modal-body"
        tabindex="0"
        ngbAutofocus
      >
        <div class="invoice-preview-card mb-2">
          <div class="card-body invoice-padding pb-0">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <div>
                <div class="logo-wrapper">
                  <h5 class="text-primary invoice-logo">Vehicle Details</h5>
                </div>
              </div>
            </div>
          </div>
          <hr class="invoice-spacing mt-0 mb-0" />
          <div class="card-body invoice-padding">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
                <div class="d-flex bd-highlight info-container">
                  <div class="w-30 info-title">Year</div>
                  <div class="w-70 info-value">
                    {{ driverProfile.VehicleYear }}
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
                <div class="d-flex bd-highlight info-container">
                  <div class="w-30 info-title">Color</div>
                  <div class="w-70 info-value">
                    {{ driverProfile.VehicleColor }}
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
                <div class="d-flex bd-highlight info-container">
                  <div class="w-30 info-title">Make</div>
                  <div class="w-70 info-value">
                    {{ driverProfile.VehicleMake }}
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
                <div class="d-flex bd-highlight info-container">
                  <div class="w-30 info-title">Model</div>
                  <div class="w-70 info-value">
                    {{ driverProfile.VehicleModel }}
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
                <div class="d-flex bd-highlight info-container">
                  <div class="w-30 info-title">Plate #</div>
                  <div class="w-70 info-value">
                    {{ driverProfile.VehiclePlateNumber }}
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 pb-0">
                <div class="d-flex bd-highlight info-container">
                  <div class="w-30 info-title">Classification</div>
                  <div class="w-70 info-value">
                    {{ driverProfile.VehicleClassification }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-info"
            (click)="modal.close('Accept click')"
            rippleEffect
          >
            Close
          </button>
        </div>
      </div>
    </ng-template>
    <!-- / Modal -->
  </div>

  <div class="modal-size-lg d-inline-block">
    <!-- Modal -->
    <ng-template
      #RideActivity
      let-modal
      class="Ride-Modal"
    >
      <div class="modal-header">
        <h5
          class="modal-title"
          id="myModalLabel160"
        >
          Ride #{{ rideMonitorModel.RideId }}
        </h5>

        <button
          type="button"
          class="close"
          (click)="modal.dismiss('Cross click')"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div
        class="modal-body"
        tabindex="0"
        ngbAutofocus
      >
        <div class="card-body invoice-padding px-0 pb-0">
          <div
            class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
          >
            <div>
              <div class="logo-wrapper">
                <h5 class="text-primary invoice-logo">Waypoints</h5>
              </div>
            </div>
          </div>
        </div>
        <hr class="mt-0 mb-2" />

        <div class="row">
          <div class="col-lg-12">
            <kendo-grid
              [kendoGridBinding]="rideWaypoints"
              [sortable]="false"
              [scrollable]="false"
            >
              <kendo-grid-column
                [width]="90"
                [sortable]="false"
                title="Stop #"
              >
                <ng-template
                  kendoGridCellTemplate
                  let-rowIndex="rowIndex+1"
                >
                  {{ rowIndex }}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column
                field="ScheduledArrivalTime"
                [width]="100"
                title="Arrival"
              >
                <ng-template
                  kendoGridCellTemplate
                  let-dataItem
                >
                  {{ dataItem.ScheduledArrivalTime | date: "shortTime" }}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column
                field="Location"
                title="Place"
              >
                <ng-template
                  kendoGridCellTemplate
                  let-dataItem
                  let-rowIndex="rowIndex"
                >
                  {{ dataItem.Name }}
                  <span class="hint">
                    {{ dataItem.Location.Street1 }}
                    {{ dataItem.Location.City }} {{ dataItem.Location.State }}
                    {{ dataItem.Location.Zipcode }}
                  </span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column
                field="Cargo"
                title="Passenger(s)	"
              >
                <ng-template
                  kendoGridCellTemplate
                  let-dataItem
                  let-rowIndex="rowIndex"
                >
                  <div *ngFor="let Passenger of dataItem.Cargo">
                    <span
                      class="k-icon k-i-arrow-up"
                      *ngIf="Passenger.ActionType == 'Pick-up'"
                    ></span>
                    <span
                      class="k-icon k-i-arrow-down"
                      *ngIf="Passenger.ActionType == 'Drop-off'"
                    ></span>
                    {{ Passenger.FirstName }} {{ Passenger.LastName }}
                    <span class="hint">({{ Passenger.CargoType }})</span>
                    <div [ngSwitch]="Passenger.RideStatusId">
                      <span
                        class="badge badge-success"
                        *ngSwitchCase="PickedUp"
                      >
                        Picked Up
                      </span>
                      <span
                        class="badge badge-warning"
                        *ngSwitchCase="NoShow"
                      >
                        No Show
                      </span>
                      <span
                        class="badge badge-success"
                        *ngSwitchCase="DroppedOff"
                      >
                        Dropped Off
                      </span>
                      <span
                        class="badge badge-danger"
                        *ngSwitchCase="NotDroppedOff"
                      >
                        Not Dropped Off
                      </span>
                      <span
                        class="badge badge-light"
                        *ngSwitchCase="CanceledInAdvance"
                      >
                        Early Cancel
                      </span>
                      <span
                        class="badge badge-light"
                        *ngSwitchCase="CanceledDueToHoliday"
                      >
                        Holiday
                      </span>
                      <span
                        class="badge badge-warning"
                        *ngSwitchCase="CanceledLate"
                      >
                        Late Cancel
                      </span>
                    </div>
                  </div>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                [width]="90"
                [sortable]="false"
                title="Action"
                [width]="90"
              >
                <ng-template
                  kendoGridCellTemplate
                  let-dataItem
                  let-rowIndex="rowIndex"
                >
                  <div class="d-flex align-items-center">
                    <div
                      ngbDropdown
                      [placement]="'left-top'"
                      container="body"
                      class="m-auto"
                    >
                      <a
                        ngbDropdownToggle
                        href="javascript:void(0);"
                        class="hide-arrow"
                        id="dropdownBrowserState"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i
                          data-feather="more-vertical"
                          class="text-primary cursor-pointer mr-50"
                        ></i>
                      </a>
                      <div
                        ngbDropdownMenu
                        class="dropdown-menu-right"
                        aria-labelledby="dropdownBrowserState"
                      >
                        <a
                          href="javascript:void(0)"
                          (click)="
                            openRideActivityLog(
                              dataItem.RideUuid,
                              dataItem.Cargo[0].RideWaypointId,
                              rowIndex
                            )
                          "
                          ngbDropdownItem
                          class="d-flex align-items-center"
                        >
                          View Activity Log
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </kendo-grid-column>
            </kendo-grid>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-info"
            (click)="modal.close('Accept click')"
            rippleEffect
          >
            Close
          </button>
        </div>
      </div>
    </ng-template>
    <!-- / Modal -->
  </div>

  <kendo-dialog
    title="Activity Log Details for Ride #{{
      rideMonitorModel.RideId
    }} - Waypoint # {{ wayPotintId }}"
    *ngIf="opened"
    (close)="close('cancel')"
    [minWidth]="250"
    [width]="900"
  >
    <kendo-grid
      [kendoGridBinding]="rideActivityLog"
      [sortable]="false"
      [scrollable]="false"
    >
      <kendo-grid-column
        [sortable]="false"
        field="#"
        [width]="50"
        title=" #"
      >
        <ng-template
          kendoGridCellTemplate
          let-rowIndex="rowIndex+1"
        >
          {{ rowIndex }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="ActionType"
        [sortable]="false"
        title="Action"
      >
      </kendo-grid-column>
      <kendo-grid-column
        field="CargoName"
        [sortable]="false"
        title="Passenger"
      >
        <ng-template
          kendoGridCellTemplate
          let-dateItem
        >
          <div *ngIf="dateItem.CargoName == ''">-</div>
          <div *ngIf="dateItem.CargoName != ''">{{ dateItem.CargoName }}</div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="DoneAtUtc"
        [sortable]="false"
        title="Done At"
        [width]="200"
      >
        <ng-template
          kendoGridCellTemplate
          let-dateItem
        >
          {{ convertDateUinx(dateItem.DoneAtUtc) }} (EEST)
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>

    <kendo-dialog-actions>
      <div class="modal-footer">
        <button
          type="button"
          (click)="close('no')"
          rippleeffect=""
          class="btn btn-info waves-effect waves-float waves-light"
        >
          Close
        </button>
      </div>
    </kendo-dialog-actions>
  </kendo-dialog>

  <kendo-dialog
    title="Attention "
    *ngIf="opendSearch"
    (close)="close('cancel')"
    [minWidth]="250"
    [width]="400"
  >
    <p style="text-align: center">{{ textValidation }}</p>

    <kendo-dialog-actions>
      <p style="text-align: center">
        <button
          type="button"
          (click)="close('no')"
          rippleeffect=""
          class="btn btn-info waves-effect waves-float waves-light"
        >
          OK, Got It
        </button>
      </p>
    </kendo-dialog-actions>
  </kendo-dialog>
</div>
